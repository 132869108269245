module.exports = [
  'upper',
  'upperCase',
  'ucFirst',
  'upperCaseFirst',
  'lcFirst',
  'lowerCaseFirst',
  'lower',
  'lowerCase',
  'sentence',
  'sentenceCase',
  'title',
  'titleCase',
  'camel',
  'camelCase',
  'pascal',
  'pascalCase',
  'snake',
  'snakeCase',
  'param',
  'paramCase',
  'dot',
  'dotCase',
  'path',
  'pathCase',
  'constant',
  'constantCase',
  'swap',
  'swapCase'
];
